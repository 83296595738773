import React from 'react';
import FlexibleLink from '../components/ui/FlexibleLink';
import Container from '../components/ui/Grid/Container';
import Headline from '../components/ui/Headline';
import Layout from '../modules/Layout';

const Page404 = () => {
  return (
    <div style={{ zIndex: 5 }}>
      <Layout
        pageName="Diese Seite konnte nicht gefunden werden."
        description="Diese Seite konnte nicht gefunden werden. "
        isHome={false}
      >
        <Container additionalClass="pt-300 pb-100">
          <Headline level={1} classLevel={2}>
            Diese Seite konnte nicht gefunden werden.
          </Headline>
          <FlexibleLink link={{ label: 'zur Startseite', url: '/' }} additionalClass="mt-50" />
        </Container>
      </Layout>
    </div>
  );
};

export default Page404;
